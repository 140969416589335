 //create cookie in browser containing jwt  cname=cookie name cvalue=value to be stored in cookie exdays=days before expiring
 export const setCookie=(cname, cvalue, exhours)=>
 {
     var d = new Date();
     //d.setTime(d.getTime() + (exdays*24*60*60*1000)); //use for days
     d.setTime(d.getTime() + (exhours*60*60*1000)); //use for hours
     var expires = "expires="+ d.toUTCString();
     document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
 }

 //read cookie from browser.  cname= cookie name
 export const getCookie=(cname,callback) =>
 {
   var temp = getCookie1(cname);
   callback(temp)
 }



 export const getCookie1=(cname)=>{
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
  }

  //loop through provided array of cookies and set expiry to effectively "reset/delete" cookie
 export const deleteCookie=(cookieArray)=>
 {
   cookieArray.map((cookie)=>
   {
     document.cookie=cookie+"=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
   }
  )
    //  document.cookie = "AuthenticationToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //  document.cookie = "LoginToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
 }
 //#endregion