import React, { Component } from 'react';
import axios from "axios";
// import './style.css';
import{setCookie,getCookie,getCookie1,deleteCookie} from "../component/Cookie"
import {CssBaseline,ThemeProvider,} from 'horizon-components-react';


class Authentication extends Component
{
    state=
    {
        logintoken:null,
        roletoken:null,
        role:null
    }

    componentDidMount() 
    {
        axios.defaults.headers.get['x-api-key'] = process.env.REACT_APP_API_KEY //only affects get requests
        axios.defaults.headers.get['Content-Type'] = 'application/json';//only affects get requests

        if (this.props.location.newRoleToken!=null) //this is for updating of roletoken with correct flags on routeback from disclaimer
        {
            deleteCookie(["RoleToken"]); //clear previously stored roletoken cookie
            setCookie("RoleToken",this.props.location.newRoleToken,1);
        }
        this.stripToken()//check for token in header
    }


    //check if token exists in cookies, else fail login  //NOT IN USE ANYMORE .KEPT IN FOR REFERENCE
    LocalAuthChecks(jwt)
    {
        //var jwt= getCookie("AuthenticationToken",this.getRole,this.loginExpired)
        if(jwt!="")
        {
            this.setState({logintoken:jwt})
            this.getRole(jwt)
        }
        else
        {
            this.loginExpired();
        }
    }

    async LocalAuthChecksV2()
    {
        console.log("in")
        var logintoken=await getCookie1("LoginToken") //check cookies for logintoken
        var roletoken=await getCookie1("RoleToken") //check cookies for roletoken

        if(logintoken=="" || roletoken=="") //we check if either token is null
        {
            this.loginExpired();
            console.log("Aa")
        }
        else
        {
            this.setState({logintoken:logintoken,roletoken:roletoken})
            console.log("Aa1")
            this.checkLogintokenValidity();
            //this.getRole(roletoken)
        }
    }


    render(){
        return(
            <ThemeProvider theme="airbus">
            <CssBaseline />

            </ThemeProvider>

            )
    }

    loginExpired()//session token expired, reroute to sso page
    {
        console.log("login fail")
     
        this.props.history.push('/sso') ; console.log("login fail")
        //WE SHOULD ADD AN ALERT HERE TO INFORM USER ON LOGIN FAIL/PLEASE LOGIN
    } 

    // loginFail() {this.props.history.push('/login') ; console.log("login fail")} //login fail. reroute to login page
    loginFail() {
        console.log(this.props)
        this.props.history.push('/sso') 
        console.log("login fail")} //login fail. reroute to login page

    loginSuccess(res) //login success, reroute to respective route (admin or user) role and isauthenticated is passed through
    {

            let roletemp=res.data.userapplicationrole[0].applicationrolename.toLowerCase();
            this.props.history.push('/'+roletemp + "/landing",  //url route
                {
                    role:res.data.userapplicationrole,//this has been passed down as user data below. can trim
                    isAuthenticated:true,
                    userData:res.data,
                    logintoken:this.state.logintoken,
                }
            ); 
        
     
    }

    //#region token
    stripToken() 
    {
        var returnstring=this.props.location.search; //gets query string section of URL
        var urlParams=new URLSearchParams(returnstring) //query string parameters or URL variable interface
        var logintokenURL= urlParams.get('logintoken') //get the returned logintoken
        var roletokenURL= urlParams.get('roletoken') //get the returned roletoken
        
        if(logintokenURL && roletokenURL) //if both tokens are successfully extracted
        {
            this.setState({logintoken:logintokenURL,roletoken:roletokenURL},()=>{this.checkLogintokenValidity()}) //store both tokens to state //if the callback is not put into a function using ()=>, it doesnt execute after waiting correctly
            //this.getRole(roletokenURL);
            // this.setState({roletoken:roletokenURL});
        }

         //else getCookie("AuthenticationToken",this.LocalAuthChecks.bind(this))
         else this.LocalAuthChecksV2();
    }

    //check if logintoken is still valid , if yes proceed to getrole, else fail login
    checkLogintokenValidity()
    { 
        axios.get(`${process.env.REACT_APP_SSO_API}/checklogintokenvalidity`,{
            params : {
                "logintoken":this.state.logintoken
            }
        }
        ).then(res =>{
            if(res.data.status=="valid") //if token is valid
            {
                setCookie("LoginToken",this.state.logintoken,1) //store token as cookie
                this.getRole();
            }
            else
            {
                console.log("here")
                this.loginExpired(); //else token is expired
            }
        }).catch(err => {
            console.log(err.response); 
        });   
    }

    //get role token from JWT .provide previously received jwt token api key
    getRole()
    {   
        //this.setState({roletoken:roletoken})
         axios.get(`${process.env.REACT_APP_SSO_API}/decoderoletoken`,{
            params : {
                "roletoken":this.state.roletoken,
            }
        }
        ).then(res => {
            //console.log(res.data)
            if(res.data.role!="" && this.state.logintoken!=""){ 
                //this.setState({role:res.data.role})    
                setCookie("RoleToken",this.state.roletoken,1) //store token as cookie
                this.loginSuccess(res);
            }
            else this.loginExpired();
        }).catch(err => {
                console.log(err.response); 
                //this.loginFail()
            });

    }
    //#endregion

}
export default Authentication;