import React from "react";
import {Route, Switch} from "react-router-dom";
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { /* Link as RouterLink, */ Link, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import {
  Grid,
  TextField,
  Card,
  Typography,
  Box,
  Collapse,
  Button,
  IconButton,
  InputAdornment,
  Backdrop, CircularProgress
} from "@material-ui/core";
import { CssBaseline, ThemeProvider } from "horizon-components-react";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import SimpleAlertMessage from '../component/alertMessage/SimpleAlertMessage';
import Infoicon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';

const styles = (theme) => ({
  root: {
    height: "100vh",
    backgroundColor: "#00205b",
  },
  cardContent: {
    justifyContent: "space-between",
  },
  cardTitleContainer: {
    padding: theme.spacing(2),
    backgroundColor: "#BDBDBD",
    // backgroundColor: "#00205b",
  },
  username: {
    padding: "16px 10%",
  },
  password: {
    padding: "16px 10%",
  },
  card: {
    height: "fit-content",
  },
  loginBtnContainer: {
    padding: "16px 10% 2%",
  },

  Link: {
    padding: "0.25px 10%",

  },

  inputx: {
    "& .MuiOutlinedInput-input": {
      padding: 12,
    },
  },
  alertOpen: {
    "& .MuiCollapse-wrapper": {
      height: 48,
      width: "100%",
      "& > div": {
        color: "#FFF",
        backgroundColor: "#D32F2F",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
  alertClosed: {
    paddingBottom: 48,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    transition: "ease-in 200ms",
  },
  loginBtn: {
    color: '#FFF',
    backgroundColor: "#00205b",
    '&:hover': {
      backgroundColor: "#283593",
      "&:disabled": {
        backgroundColor: "#00205b"
      }
    }

  },
  CancelBtn: {
    color: '#FFF',
    backgroundColor: "#fbca37",
    '&:hover': {
      backgroundColor: "#fbca33",
      "&:disabled": {
        backgroundColor: "#00205b"
      }
    }
    

  },
});
axios.defaults.headers.common['Content-Type'] = 'application/json';
var passwordValidator = require('password-validator'); // for regex library 

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& .MuiTextField-root': {
//       margin: theme.spacing(1),
//       width: 200,
//     },
//   },
// }));



class ResetPassword extends React.Component{
state = {
    username: this.props.location.state.username,
    usernamevalid: true,
    newpassword:'',
    newpasswordvalid:false,
    confirmnewpassword:'',
    newPasswordErrorMessage:'',
    confirmNewPasswordErrorMessage:'',
    confirmnewpasswordvalid:false,
    verificationcode:'',
    verificationcodevalid:false,
    open: true,
    scroll: 'paper',
    allvalid:false,
    showPassword:false,
    showNewPassword:false,
    alert:null,
    isAlert:false,
    isBackdrop: false,
    validationError: null, //og false

  };
  // this.handleEmailChange = this.handleEmailChange.bind(this)
  // this.handlePasswordChange = this.handlePasswordChange.bind(this)
  // this.handleSubmit = this.handleSubmit.bind(this)
  // this.handleChange = this.handleChange.bind(this);
  // this.resetPassword=this.resetPassword.bind(this)
  // componentDidUpdate(prevProps) {
  //   if (this.props.location.state != prevProps.location.state) {
  //     this.setState({ username: this.props.location.state.username, usernameValid:true });
  //   }
  // }

  // componentDidUpdate(prevProps) {
  //   if (this.props.location.state != prevProps.location.state) {
  //     this.setState({ username: this.props.location.state.username, usernamevalid:true });
  //   }
  // }


handleClickShowPassword = () => this.setState({showPassword:!this.state.showPassword}); //handles hide password in input field
handleClickShowNewPassword = () => this.setState({showNewPassword:!this.state.showNewPassword}); //handles hide password in input field
handleMouseDownPassword = (event) => event.preventDefault(); //handles hide password in input field

sendAlert=(title,classType,timeout)=> //handle alert message banner
{
    var temp={title:title,classType:classType}
    this.setState({alert:temp},()=>{this.setState({isAlert:true})}) //sets alert values, then shows alert
    setTimeout(()=>this.setState(this.setState({isAlert:false})),timeout) //waits "timeout" seconds. then hides alert
}

handleSubmit=(e)=>{ // handle calling of axios function

  e.preventDefault();
  if(this.state.allvalid && (this.state.newpassword=== this.state.confirmnewpassword))
  
   this.resetPassword()

  console.log("success")
}


handleCancel=(e)=>{ // handle calling of axios function

  e.preventDefault();
this.props.history.push("/login")
}


validateEmail (username) {  // validate email syntax
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(username)
}


validateNewPassword (newpassword) { // validate newpassword syntax
  var schema = new passwordValidator();
  schema.is().min(8).has().uppercase().has().lowercase().has().digits(); 
console.log(schema.validate(newpassword))
return schema.validate(newpassword)

}
validateConfirmNewPassword (confirmnewpassword) { // validate confirmnewpassword syntax
  var schema = new passwordValidator();
  schema.is().min(8).has().uppercase().has().lowercase().has().digits(); 
console.log(schema.validate(confirmnewpassword))
return schema.validate(confirmnewpassword)

}

validateVerificationCode (verificationcode) { // validate verification code syntax
  var schema = new passwordValidator();
  schema.is().min(6).has().digits(); 
console.log(schema.validate(verificationcode))
return schema.validate(verificationcode)
}



handleChange = (event)=>{
  // check it out: we get the evt.target.name (which will be either "email" or "password")
  // and use it to target the key on our `state` object with the same name, using bracket syntax

 
  this.setState({verificationcodevalid:false, newpasswordvalid:false, confirmnewpasswordvalid:false, allvalid:false, [event.target.name]: event.target.value},() => this.evalchange())
}

evalchange=()=> //evaluate whether individual fields are valid and setting the respectiive states
{
  const usernamevalid = this.validateEmail(this.state.username)
  const newpasswordvalid = this.validateNewPassword(this.state.newpassword) 
  const confirmnewpasswordvalid = this.validateConfirmNewPassword(this.state.confirmnewpassword) 
  const verificationcodevalid = this.validateVerificationCode(this.state.verificationcode) 
  if(this.state.confirmnewpassword ===  this.state.newpassword)
  this.setState({ usernamevalid, newpasswordvalid,confirmnewpasswordvalid, verificationcodevalid},()=>this.checkAllFields())
}

checkAllFields=()=> // check ALL fields are valid before enabling submit button
{

  if(this.state.usernamevalid && this.state.newpasswordvalid && this.state.confirmnewpasswordvalid && this.state.verificationcodevalid && this.state.newpassword === this.state.confirmnewpassword)
  {  this.setState({allvalid:true})
  }
}

resetPassword=()=>{   // axios post call to reset password with verification code

  this.setState({ isBackdrop: true });
  axios.post(`${process.env.REACT_APP_SSO_API}/resetpassword`,{
  
      username:this.state.username, 
      newpassword:this.state.newpassword,
      confirmnewpassword:this.state.confirmnewpassword,
      verificationcode:this.state.verificationcode
  
}
).then(res => {
//console.log(res.data[0])
//   this.props.history.push({pathname:'/auth', newRoleToken:res.data[0].roletoken})
alert("Successfully Reset Password","SuccessAlert",4000);
console.log(res)
setTimeout((props)=>{this.props.history.push('/login')},4000);



}).catch((err) => {
// this.sendAlert("Error resetting password, Please retry later","ErrorAlert");
console.log(err.response.data.message.message);
const errorMessage= err.response.data.message.message
//  + 'Please try again later'
this.setState({validationError:errorMessage,isBackdrop:false})
setTimeout(() => {
  this.setState({validationError:''})}, 4000);

// setTimeout((props)=>{this.props.history.push('/login')},4000);

}).finally(() => {
  setTimeout(() => this.setState({ validationError: '',isBackdrop:false }), 4000);
});
;

}

render(){
  const { classes } = this.props;
  console.log(this.props.location.state.username)
  return (
    <ThemeProvider theme="airbus">
      <Backdrop className={classes.backdrop} open={this.state.isBackdrop}>
        <CircularProgress style={{ color: "#FFF" }} />
      </Backdrop>
      <CssBaseline />
      <Grid
        container
        className={classes.root}
        justify="center"
        alignItems="center"
      >
        <Grid item xs={7} sm={5} md={3}>
          <Card className={classes.card}>
            <Grid
              container
              className={classes.cardContent}
              direction="column"
            >
              <Grid item className={classes.cardTitleContainer}>
                <Typography
                  align="center"
                  variant="h3"
                  style={{
                    fontWeight: "bold",
                    color: "#00205b",
                  }}
                >
                  FHS
                </Typography>
                <Typography variant="h4" align="center" style={{ color: '#FFEB3B', fontWeight: 'bold' }}>
                  Reset Password Form
                </Typography>

              </Grid>
              <Grid
                item
                xs={12}
                className={
                  !!this.state.validationError
                    ? classes.alertOpen
                    : classes.alertClosed
                }
              >
                <Collapse in={!!this.state.validationError} timeout="auto">
                 <div style={{textAlign:"center"}}> {this.state.validationError}</div>
                </Collapse>
              </Grid>
              <Grid container>
                <Grid item xs={12} className={classes.username}>
                  <TextField
                    className={classes.inputx}
                    // defaultValue={this.props.location.state.username}
                    value={this.state.username}
                    fullWidth
                    name="username"
                    onChange={this.handleChange}
                    placeholder="Username"
                    helperText={this.state.userNameErrorMessage}
                    error={!this.state.usernamevalid && this.state.username.length > 0}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} className={classes.password}>
                  <TextField
                    className={classes.inputx}
                    type={this.state.showPassword ? "text" : "password"}
                    name="newpassword"
                    error={!this.state.newpasswordvalid && this.state.newpassword.length > 0}
                    helperText={this.state.newPasswordErrorMessage}
                    fullWidth
                    placeholder="New Password"
                    InputProps={{
                      endAdornment:
                      
                        <InputAdornment position="end">
<Tooltip title="Min. 8 Characters with 1 Uppercase 1 Lowercase 1 number and 1 special character">
   <IconButton   style={{ padding: 0 }}>
     <Infoicon />
   </IconButton>
   </Tooltip>
                          <IconButton
                            style={{ padding: 0 }}
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                          >
                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                    }}
                    value={this.state.newpassword}
                    onChange={this.handleChange
                    }
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.password}>
                  <TextField
                    className={classes.inputx}
                    type={this.state.showNewPassword ? "text" : "password"}
                    error={!this.state.confirmnewpasswordvalid && this.state.confirmnewpassword.length > 0}
                    helperText={this.state.confirmNewPasswordErrorMessage}
                    name="confirmnewpassword"
                    fullWidth
                    placeholder="Confirm New Password"
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end">
                          <Tooltip title="Min. 8 Characters with 1 Uppercase 1 Lowercase 1 number and 1 special character">
   <IconButton   style={{ padding: 0 }}>
     <Infoicon />
   </IconButton>
   </Tooltip>
                          <IconButton
                            style={{ padding: 0 }}
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowNewPassword}
                          >
                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                    }}
                    value={this.state.confirmnewpassword}
                    onChange={this.handleChange
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} className={classes.username}>
                  <TextField
                    className={classes.inputx}
                    value={this.state.verificationcode}
                    fullWidth
                    placeholder="Verification Code"
                    // helperText={this.state.userNameErrorMessage}
                    name="verificationcode"
                    onChange={this.handleChange}
                    error={!this.state.verificationcodevalid && this.state.verificationcode.length > 0}
                    variant="outlined"
                  />
                </Grid>
              </Grid>

              <Grid
                container
                className={classes.loginBtnContainer}
                justify="center"
              >

                <Grid item xs={12}>
                  <Button
                    disabled={!this.state.allvalid}
                    variant="contained"
                    className={classes.loginBtn}
                    type="submit"
                    onClick={this.resetPassword}
                    style={{
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>

           
         
              <Grid
                container
                className={classes.loginBtnContainer}
                justify="center"
              >

                <Grid item xs={12}>
                  <Button
                    disabled={this.state.usernameValid}
                    variant="contained"
                    className={classes.CancelBtn}
                    type="submit"
                    onClick={this.handleCancel}
                    style={{
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                
              </Grid>
          </Card>
        </Grid>

      </Grid >

    </ThemeProvider >
  );

                  }}

//   return (

//     <div>
// {/*  
//       <div class = "overlay" > */}
//       {/* <Dialog
//         maxWidth={'xs'}
//         fullWidth={'true'}
//         open={this.state.open}
//         onClose={this.handleClose}
//         scroll={this.state.scroll}
//         aria-labelledby="scroll-dialog-title"
//       > */}
//            <div >{this.state.isAlert && <SimpleAlertMessage title={this.state.alert.title} classType ={this.state.alert.classType}/> }</div>

//         {/* <DialogTitle style={{paddingLeft:"7rem"}} id="scroll-dialog-title"><b>Reset Password Form</b></DialogTitle> */}
//         {/* <DialogContent>
//           <DialogContentText> */}
//           <form  noValidate autoComplete="off">
//       <div>
//       <Grid container spacing={2}  
//   alignItems='center'  direction='column'>
//       {/* <form >  */}
        
//         <Grid  item xs={6}>
//         <label>
//           Username: 
//           <Input style={{paddingLeft:"1rem",}} name="email" type="text" value={this.state.email} onChange={this.handleChange} error={!this.state.emailvalid}/>  </label>
//           </Grid>
//           <Grid item xs={6}>
//           <label>
//           New password: 
//           <Tooltip title="Min. 8 Characters with 1 Uppercase 1 Lowercase 1 number and 1 special character ">
//   <IconButton style={{paddingBottom:"1rem",marginRight:"1rem"}}>
//     <Infoicon />
//   </IconButton>
// </Tooltip>
//           <Input id="standard-adornment-password" type={this.state.showPassword ? "text" : "password"}  style={{paddingLeft:"1rem"}} name ="newpassword"  endAdornment={
//               <InputAdornment position="end">
//                 <IconButton
//                   aria-label="toggle password visibility"
//                   onClick={this.handleClickShowPassword}
//                   onMouseDown={this.handleMouseDownPassword}
//                 >
//                   {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
//                 </IconButton>
//               </InputAdornment>
//             }value={this.state.newpassword} onChange={this.handleChange} error={!this.state.newpasswordvalid}/>  </label>
//           </Grid>

//           <Grid item xs={6}>
//           <label>
//           Confirm New Password: 
     
//           <Input   id="standard-adornment-password" type={this.state.showNewPassword ? "text" : "password"} style={{paddingLeft:"1rem"}} name="confirmnewpassword" endAdornment={
//               <InputAdornment position="end">
   
//                 <IconButton
//                   aria-label="toggle password visibility"
//                   onClick={this.handleClickShowNewPassword}
//                   onMouseDown={this.handleMouseDownPassword}
//                 >
//                   {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
//                 </IconButton>
//               </InputAdornment>
//             } value={this.state.confirmnewpassword} onChange={this.handleChange} error={!this.state.confirmnewpasswordvalid}/>  </label>
//           </Grid>

//           <Grid item xs={6}>
//           <label>
//           Verification Code: 
//           <Input style={{paddingLeft:"1rem"}} name = "verificationcode" type="text" value={this.state.verificationcode} onChange={this.handleChange} error={!this.state.verificationcodevalid}/>  </label>
//           </Grid>

        
        
//       {/* </form> */}
//       </Grid>
//     </div>
//     </form>
//     <br></br>
//        <div style={{paddingLeft:'10rem'}}>
//       <Button style={{paddingLeft:'10rem',marginLeft:"0.5rem"}} contained color="error"  type ="submit" onClick={(e) => this.handleCancel(e)}>
//              Cancel
//             </Button>
//           <Button  style={{paddingLeft:'10rem',marginLeft:"0.5rem"}} contained color="success" disabled={!this.state.allvalid} type ="submit" onClick={(e) => this.handleSubmit(e)}>
//              Submit
//             </Button>
//             </div>

//           {/* </DialogContentText>
//         </DialogContent>
//         <DialogActions> */}
//           {/* <div>
//           <Button contained color="error" onClick={this.handleClear} style={{marginRight:"0.5rem"}}>
//            Clear field
//           </Button>
//           <Button  contained color="success" onClick={this.handleSubmit}>
//             Submit
//           </Button>
//           </div> */}
//         {/* </DialogActions> */}
//       {/* </Dialog> */}
//     </div>
//     // </div>
//   );


//onsubmit 






export default withRouter(withStyles(styles)(ResetPassword));