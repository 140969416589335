import React from 'react';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import UploadIcon from '@material-ui/icons/CloudUpload';
import ContactIcon from '@material-ui/icons/Contacts';
import {Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, useTheme} from '@material-ui/core/';
import {withRouter, BrowserRouter} from "react-router-dom";

const drawerWidth = "9rem";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: "1.2rem",
    color: "white"
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,

  },
  drawerOpen: {
    marginLeft: -drawerWidth,
    background: '#00205b',
    width: drawerWidth,
    top: "4rem",   // theme.spacing.unit (2)
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  drawerHeader: {       //header for drawer (menu & chervon)
    display: 'flex',
    alignItems: 'right',
    // padding: theme.spacing(0, 1),
    // ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  
  drawerClose: {
    marginLeft: '0px',
    display: 'flex',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: '#00205b',
    top: theme.spacing.unit * 10,
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
      
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },


}));

function Sidebar(props) {   // set initial state for drawer

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  
  const handleDrawerOpen = () => {
    setOpen(true);
    props.drawerState(true); //send drawer state to parent so we can scale content div
  };

  const handleDrawerClose = () => {
    setOpen(false);
    props.drawerState(false);//send drawer state to parent so we can scale content div
  };

//  // Similar to componentDidMount and componentDidUpdate:
//  useEffect(() => {
//   // Update the document title using the browser API
//   console.log("start")
// });

  const onClickHandler=(e,index)=>{ //for sidebar options routing
    //console.log("test"+index)

        if(index==0) props.history.push( "/admin/uploadFile");
        if(index==1) props.history.push( "/admin/downloadFile");
        if(index==2) props.history.push( "/admin/contacts");
}

  return (
    
    <div className={classes.root}>
      <Drawer 
        variant="permanent"
        className={clsx(classes.drawer, {    //handle open and close states
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({   //handle open and close states
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
         <div className={classes.drawerHeader}>
       {!open && <IconButton  // icon button for opening of drawer
             color="inherit"
             aria-label="open drawer"
             onClick={handleDrawerOpen}
             className={clsx(classes.menuButton, {
               [classes.hide]: open,
             })}
           >
             <MenuIcon />
           </IconButton>}
          {open &&<IconButton  onClick={handleDrawerClose} // icon button for closing of drawer
          > 
            {<ChevronLeftIcon style={{color:'white'}}/>}
          </IconButton>}
        </div>
        <BrowserRouter>
        <List>
          <ListItem id={"upload"} alignItems="flex-start" button key={'Upload File'} onClick= {e=>onClickHandler(e,0)}>
            <ListItemIcon  style={{color:'white'}} ><UploadIcon/></ListItemIcon>         
            <ListItemText  disableTypography = {true } style={{color:'white', fontSize:'0.9em', fontWeight: 'bold'}} primary={'Upload File'}/>                                      
          </ListItem>
          <ListItem id={'download'} alignItems="flex-start" button key={'Download File'} onClick= {e=>onClickHandler(e,1)}>
            <ListItemIcon  style={{color:'white'}} ><DownloadIcon/></ListItemIcon>         
            <ListItemText  disableTypography = {true } style={{color:'white', fontSize:'0.9em', fontWeight: 'bold' }} primary={'Download File'}/>                                      
          </ListItem>
          <ListItem id={'contacts'} alignItems="flex-start" button key={'Contact List'} onClick= {e=>onClickHandler(e,2)}>
            <ListItemIcon  style={{color:'white'}} ><ContactIcon/></ListItemIcon>         
            <ListItemText  disableTypography = {true } style={{color:'white', fontSize:'0.9em', fontWeight: 'bold'}} primary={'Contact List'}/>                                      
          </ListItem>
          </List>
        </BrowserRouter>   
      </Drawer>
    </div>
  );
}

export default withRouter(Sidebar);