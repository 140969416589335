import React, { Component } from 'react';
import '../index.css';
import { withStyles, useTheme } from '@material-ui/core/styles';
import Sidebar from "../component/sidebar/sidebar.js";

import {
    Button,
    CssBaseline,
    ThemeProvider,
  } from 'horizon-components-react';

class User extends Component
{
    state={
        drawerIsOpen:true,
    }
    drawerState=(isOpen)=>{this.setState({drawerIsOpen:isOpen})}
    
    render()
    {
        return(
            <ThemeProvider theme="airbus">
            <CssBaseline />
            <div id="parent">
                <div id="fixedUI" >
                <Sidebar drawerState={this.drawerState.bind(this)}/>
                </div>
            </div>
            </ThemeProvider>
        )
    }
}

export default User;