import React, { Component } from "react";
import {
  Grid,
  TextField,
  Card,
  Typography,
  Box,
  Collapse,
  Button,
  IconButton,
  InputAdornment
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import { Backdrop, CircularProgress } from "@material-ui/core";
import "../index.css";
import { /* Link as RouterLink, */ Link, withRouter } from "react-router-dom";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import SimpleAlertMessage from "../component/alertMessage/SimpleAlertMessage";

import { CssBaseline, ThemeProvider } from "horizon-components-react";

// const LinkBehavior = React.forwardRef((props, ref) => (
//   <RouterLink ref={ref} to={"/checkemail"} {...props} />
// ));

const styles = (theme) => ({
  root: {
    height: "100vh",
    backgroundColor: "#00205b",
  },
  cardContent: {
    justifyContent: "space-between",
  },
  cardTitleContainer: {
    padding: theme.spacing(2),
    backgroundColor: "#BDBDBD",
    // backgroundColor: "#00205b",
  },
  username: {
    padding: "16px 10%",
  },
  password: {
    padding: "16px 10%",
  },
  card: {
    height: "fit-content",
  },
  loginBtnContainer: {
    padding: "16px 10% 5%",
  },

  Link: {
    padding: "0.25px 10%",

  },

  inputx: {
    "& .MuiOutlinedInput-input": {
      padding: 12,
    },
  },
  alertOpen: {
    "& .MuiCollapse-wrapper": {
      height: 48,
      width: "100%",
      "& > div": {
        color: "#FFF",
        backgroundColor: "#D32F2F",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
  alertClosed: {
    paddingBottom: 48,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    transition: "ease-in 200ms",
  },
  loginBtn: {
    color: '#FFF',
    backgroundColor: "#00205b",
    '&:hover': {
      backgroundColor: "#283593",
      "&:disabled": {
        backgroundColor: "#00205b"
      }
    }

  }
});
class Login extends Component {
  state = {
    username: "",
    password: "",
    successURL: null,
    failURL: null,
    applicationName: null, //header values from client
    logintoken: null,
    roletoken: null, //
    isBackdrop: false,
    alert: null,
    isAlert: false,
    validationError: null, //og false
    showPassword: false,
    usernameValid: false,
    userNameErrorMessage: null,

  };

  componentDidMount() {
    this.stripURL();

    axios.defaults.headers.post["x-api-key"] = process.env.REACT_APP_API_KEY;
    axios.defaults.headers.post["Content-Type"] = "application/json";
  }

  handleClickShowPassword = () => this.setState({ showPassword: !this.state.showPassword });

  // checkUserName = async () => { // check username active in database
  //   let isSuccessful = false;
  //   this.setState({ isBackdrop: true })

  //   axios.get(`${process.env.REACT_APP_SSO_API}/forgetpassword`, {
  //     params: {
  //       "username": this.state.username
  //     }
  //   }

  //   ).then(res => {
  //     console.log(res.message);
  //     isSuccessful = true;
  //   }).catch(res => {
  //     console.log(res.response.data.message);
  //     this.setState({ userNameError: true, userNameErrorMessage: "Incorrect Usernmame" })
  //     this.setState({ isBackdrop: false })
  //   })

  //   console.log('return:,', isSuccessful);
  //   return isSuccessful;

  // }

  ///strips url for required information (in this case its for the success and fail callback URLs, and the application name)
  stripURL = () => {
    var returnstring = this.props.location.search; //gets query string section of URL
    var urlParams = new URLSearchParams(returnstring); //query string parameters or URL variable interface
    var success = urlParams.get("success_callbackurl"); //get the success callback URL
    var fail = urlParams.get("failure_callbackurl"); //get the fail callback URL
    var app = urlParams.get("applicationname"); //get the fail callback URL
    //   this.setState({successURL:success,failURL:fail,applicationName:app})

    if (success === null) {
      this.setState({
        successURL: null,
        failURL: null,
        applicationName: "SSO",
      });
    } else {
      this.setState({
        successURL: success,
        failURL: fail,
        applicationName: app,
      });
    }
  };

  routeBack = (isSuccess) => {
    if (isSuccess && this.state.successURL !== null)
      window.location.assign(
        this.state.successURL +
        "?logintoken=" +
        this.state.logintoken +
        "&roletoken=" +
        this.state.roletoken
      );
    else if (
      isSuccess &&
      this.state.successURL === null &&
      this.state.failURL === null
    )
      this.props.history.push(
        "/auth" +
        "?logintoken=" +
        this.state.logintoken +
        "&roletoken=" +
        this.state.roletoken
      );
    else if (!isSuccess && this.state.failURL !== null)
      window.location.assign(this.state.failURL);
    else return;
  };

  sendAlert = (
    title,
    classType,
    timeout //handle alert message banner
  ) => {
    var temp = { title: title, classType: classType };
    this.setState({ alert: temp }, () => {
      this.setState({ isAlert: true });
    }); //sets alert values, then shows alert
    setTimeout(() => this.setState(this.setState({ isAlert: false })), timeout); //waits "timeout" seconds. then hides alert
  };

  //#region DB

  //send login request to SSO API. requires Username, Password,ApplicationName.
  sendLogin = () => {
    // this.checkUserName();

    this.setState({ isBackdrop: true });
    axios
      .post(`${process.env.REACT_APP_SSO_API}/login`, {
        username: this.state.username,
        userpassword: this.state.password,
        applicationname: this.state.applicationName,
        successcallbackURL: this.state.successURL,
        failurecallbackURL: this.state.failURL,
      })
      .then((res) => {
        console.log(res);
        this.setState({
          logintoken: res.data.logintoken,
          roletoken: res.data.roletoken,
        });
        this.routeBack(true);
      })
      .catch((err) => {
        if (err) {
          console.log(err.response.data.message);
          this.setState({
            password: "",
            validationError: err.response.data.message,
            isBackdrop: false,
            passwordErrorMessage: "Wrong Password", passwordError: true, password: ""

          });
        }
        this.routeBack(false);
      })
      .finally(() => {
        setTimeout(() => this.setState({ validationError: "" }), 3000);
      });
  };

  validateUserName(username) { // validate email syntax
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(username)
  }

  handleUsernameChange = (e) => { //handle field input
    const username = e.target.value
    const usernameValid = this.validateUserName(username)

    this.setState({
      username: e.target.value,
      usernameValid: usernameValid
    })
    if (usernameValid || username.length == 0) { this.setState({ userNameErrorMessage: "" }) }
    else { this.setState({ userNameErrorMessage: "Invalid Username" }) }
  }


  //#endregion

  render() {
    const { classes } = this.props;

    return (
      <ThemeProvider theme="airbus">
        <Backdrop className={classes.backdrop} open={this.state.isBackdrop}>
          <CircularProgress style={{ color: "#FFF" }} />
        </Backdrop>
        <CssBaseline />
        <Grid
          container
          className={classes.root}
          justify="center"
          alignItems="center"
        >
          <Grid item xs={7} sm={5} md={3}>
            <Card className={classes.card}>
              <Grid
                container
                className={classes.cardContent}
                direction="column"
              >
                <Grid item className={classes.cardTitleContainer}>
                  <Typography
                    align="center"
                    variant="h3"
                    style={{
                      fontWeight: "bold",
                      color: "#00205b",
                    }}
                  >
                    FHS
                  </Typography>
                  <Typography variant="h4" align="center" style={{ color: '#FFEB3B', fontWeight: 'bold' }}>
                    SFR File Upload
                  </Typography>

                </Grid>
                <Grid
                  item
                  xs={12}
                  className={
                    !!this.state.validationError
                      ? classes.alertOpen
                      : classes.alertClosed
                  }
                >
                  <Collapse in={!!this.state.validationError} timeout="auto">
                    <Box>{this.state.validationError}</Box>
                  </Collapse>
                </Grid>
                <Grid container>
                  <Grid item xs={12} className={classes.username}>
                    <TextField
                      className={classes.inputx}
                      value={this.state.username}
                      fullWidth
                      placeholder="Username"
                      helperText={this.state.userNameErrorMessage}
                      onChange={this.handleUsernameChange}
                      error={!this.state.usernameValid && this.state.username.length > 0}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.password}>
                    <TextField
                      className={classes.inputx}
                      type={this.state.showPassword ? "text" : "password"}
                      error={this.state.passwordError}
                      helperText={this.state.passwordErrorMessage}
                      fullWidth
                      placeholder="Password"
                      InputProps={{
                        endAdornment:
                          <InputAdornment position="end">
                            <IconButton
                              style={{ padding: 0 }}
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                            >
                              {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                      }}
                      value={this.state.password}
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="column"
                  alignItems="flex-end"
                  justify="flex-end"
                  className={classes.Link}
                >
                  {/* <Typography variant={"h10"} style={{align:"right"}}> */}
                  <Link style={{ color: '#00205b', fontSize: "0.85rem", textDecoration: 'none' }} to={{ pathname: "/checkemail" }}>Reset Password</Link>
                  {/* </Typography>  */}

                </Grid>
                <Grid
                  container
                  className={classes.loginBtnContainer}
                  justify="center"
                >

                  <Grid item xs={12}>
                    <Button
                      disabled={!this.state.usernameValid}
                      variant="contained"
                      className={classes.loginBtn}
                      type="submit"
                      onClick={this.sendLogin}
                      style={{
                        fontWeight: "bold",
                        width: "100%",
                      }}
                    >
                      Login
                    </Button>
                  </Grid>
                </Grid>

              </Grid>

            </Card>
          </Grid>

        </Grid >

      </ThemeProvider >
    );
  }
}

export default withRouter(withStyles(styles)(Login));
