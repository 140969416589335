import React from "react";
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { /* Link as RouterLink, */ Link, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Input, Grid, Card,  
  Typography,
  Box,
  TextField,
  Collapse,
  Button,
  IconButton,
  InputAdornment,
  Backdrop, CircularProgress } from '@material-ui/core';
  import { CssBaseline, ThemeProvider } from "horizon-components-react";


axios.defaults.headers.get['x-api-key'] = process.env.REACT_APP_API_KEY;

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& .MuiTextField-root': {
//       margin: theme.spacing(1),
//       width: 200,
//     },
//   },
// }));
// handleEmailChange = this.handleEmailChange.bind(this)
// handleSubmit = this.handleSubmit.bind(this)

const styles = (theme) => ({
  root: {
    height: "100vh",
    backgroundColor: "#00205b",
  },
  cardContent: {
    justifyContent: "space-between",
  },
  cardTitleContainer: {
    padding: theme.spacing(2),
    backgroundColor: "#BDBDBD",
    // backgroundColor: "#00205b",
  },
  username: {
    padding: "10px 10%",
  },
  inputx: {
    "& .MuiOutlinedInput-input": {
      padding: 12,
    },
  },
  alertOpen: {
    "& .MuiCollapse-wrapper": {
      height: 48,
      width: "100%",
      "& > div": {
        color: "#FFF",
        backgroundColor: "#D32F2F",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
  alertClosed: {
    paddingBottom: 40,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    transition: "ease-in 200ms",
  },
  loginBtn: {
    color: '#FFF',
    backgroundColor: "#00205b",
    '&:hover': {
      backgroundColor: "#283593",
      "&:disabled": {
        backgroundColor: "#00205b"
      }
    }
    

  },
  loginBtnContainer: {
    padding: "16px 1% 2%",
  },

  CancelBtn: {
    color: '#FFF',
    backgroundColor: "#fbca37",
    '&:hover': {
      backgroundColor: "#fbca33",
      "&:disabled": {
        backgroundColor: "#00205b"
      }
    }
    

  },

  
});

class CheckEmail extends React.Component{
state = {
    email: '',
    emailValid: false,
    emailErrorMessage:'',
    validationError:null,
    open: true,
    scroll: 'paper',
    isBackdrop: false,
  };



handleSubmit=(event)=>{ // handle calling of axios function
  event.preventDefault();
  
  this.checkUserName(event)

  return(console.log("success"))
}


validateEmail =(email) =>{ // validate email syntax
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

handleEmailChange=(e) =>{ //handle field input
    const email = e.target.value
    const emailValid = this.validateEmail(email) 

    this.setState({
      email: e.target.value,
      emailValid: emailValid
    })

    if (emailValid || email.length == 0) { this.setState({ emailErrorMessage: "" }) }
    else { this.setState({ emailErrorMessage: "Invalid Username" }) }
}

handleCancel=(e)=>{ // handle calling of axios function

  e.preventDefault();
this.props.history.push("/")
}

checkUserName=()=>{ // check username active in database
  this.setState({ isBackdrop: true });
  axios.get(`${process.env.REACT_APP_SSO_API}/forgetpassword`,{
      params : {
          "username":this.state.email
      }
  }

).then(res => {
 //console.log(res.data[0])
// this.props.history.push('/resetpassword2')
 alert('A verification code will be sent to: ' + this.state.email);
this.props.history.push('/resetpassword',{username: this.state.email});
console.log(res.data)
}).catch(err => {

  console.log(err.response); 
  this.setState({ validationError: err.response.data.message, isBackdrop:false})
  // alert( this.state.email + " is not registered");

}).finally(() => {
  setTimeout(() => this.setState({ validationError: "",email:'',isBackdrop:false }), 3000);
});
};

 
render(){
  const { classes } = this.props;
  return (
    <ThemeProvider theme="airbus">
    <Backdrop className={classes.backdrop} open={this.state.isBackdrop}>
      <CircularProgress style={{ color: "#FFF" }} />
    </Backdrop>
  <Grid
    container
    className={classes.root}
    justify="center"
    alignItems="center"
  >
    <Grid item xs={7} sm={5} md={3}>
      <Card className={classes.card}>
        <Grid
          container
          className={classes.cardContent}
          direction="column"
        >
          <Grid item className={classes.cardTitleContainer}>
            <Typography
              align="center"
              variant="h3"
              style={{
                fontWeight: "bold",
                color: "#00205b",
              }}
            >
              FHS
            </Typography>
            <Typography variant="h4" align="center" style={{ color: '#FFEB3B', fontWeight: 'bold' }}>
              Reset Password Form
            </Typography>

          </Grid>


          <Grid
            item
            xs={12}
            className={
              !!this.state.validationError
                ? classes.alertOpen
                : classes.alertClosed
            }
          >
            <Collapse in={!!this.state.validationError} timeout="auto">
              <Box>{this.state.email + " is not registered"}</Box>
            </Collapse>
          </Grid>
          <Grid container>
            <Grid item xs={12} className={classes.username}>
              <TextField
                className={classes.inputx}
                value={this.state.email}
                fullWidth
                name="email"
                onChange={this.handleEmailChange}
                placeholder="Username"
                helperText={this.state.emailErrorMessage}
                error={!this.state.emailValid && this.state.email.length > 0}
                variant="outlined"
              />
      
      <Grid
                container
                className={classes.loginBtnContainer}
                justify="center"
              >

                <Grid item xs={12}>
                  <Button
                    disabled={!this.state.emailValid}
                    variant="contained"
                    className={classes.loginBtn}
                    type="submit"
                    onClick={this.checkUserName}
                    style={{
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
              <Grid
                  container
                  className={classes.loginBtnContainer}
                  justify="center"
                >

                  <Grid item xs={12}>
                    <Button
                      disabled={this.state.emailValid}
                      variant="contained"
                      className={classes.CancelBtn}
                      type="submit"
                      onClick={this.handleCancel}
                      style={{
                        fontWeight: "bold",
                        width: "100%",
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              </Grid>
              </Grid >
              </Card>
            
    </Grid >
    </Grid >
    </ThemeProvider >
    );
   

          }    
        }


export default withRouter(withStyles(styles)(CheckEmail));

    //    <div class = "overlay" > 
    //    <Dialog
    //     maxWidth={'xs'}
    //     fullWidth={'true'}
    //     open={this.state.open}
    //     onClose={this.handleClose}
    //     scroll={this.state.scroll}
    //     aria-labelledby="scroll-dialog-title"
    //   >
    //     <DialogTitle style={{paddingLeft:"7rem"}} id="scroll-dialog-title"><b>Forget Password Form</b></DialogTitle>
    //     <DialogContent>
    //       <DialogContentText>
    //       <form  noValidate autoComplete="off">
    //   <div>

    //     <label>
    //       Username: 
    //       <Input style={{paddingLeft:"1rem"}} type="text" value={this.state.email} onChange={this.handleEmailChange} error={!this.state.valid}/>  </label>
    //       <br></br>
    //       <br></br>
    //       <div style={{paddingLeft:"10rem"}}>
    //       <Button contained color="error"  type ="submit" onClick={(e) => this.handleCancel(e)} style={{marginLeft:"0.5rem"}}>
    //          Cancel
    //         </Button>
    //       <Button contained color="success"  type ="submit" disabled={!this.state.valid} onClick={this.handleSubmit} style={{marginLeft:"0.5rem"}}>
    //          Submit
    //         </Button>
    //         </div>
    // </div>
    // </form>

    //       </DialogContentText>
    //     </DialogContent>
    //     <DialogActions>
    //       <div>
    //       <Button contained color="error" onClick={this.handleClear} style={{marginRight:"0.5rem"}}>
    //        Clear field
    //       </Button>
    //       <Button  contained color="success" onClick={this.handleSubmit}>
    //         Submit
    //       </Button>
    //       </div>
    //     </DialogActions>
    //   </Dialog>
    // </div> 
  

