import React from 'react';
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Login from "./views/LoginPage.js"
import User from "./views/User.js"
// import Admin from "./views/Admin.js"
import Auth from "./views/Authentication.js"
import ChangePassword from "./views/ChangePassword.js"
import CheckEmail from "./views/CheckEmail.js"
import ResetPassword from "./views/ResetPassword.js"
import ChangePasswordInLogin from "./views/ChangePasswordInLogin.js";


const hist = createBrowserHistory(); //This is so we can use "back" for client routing


ReactDOM.render(
    <Router history={hist}>
      <Switch>
        <Route path="/login" component={Login}/>
        <Route path="/user" component={User} />
        {/* <Route path="/admin" component={Admin} /> */}
        {/* <Route path="/auth" component={Auth}/> */}

        <Route path="/auth" component={Auth}/>
        <Route path="/user" component={User}/>
        <Route path="/changepassword" component={ChangePassword} />
        <Route path="/checkemail" component={CheckEmail} />
        <Route path="/resetpassword" component={ResetPassword} />
        <Route path="/changepasswordinlogin" component={ChangePasswordInLogin} />
        <Redirect from="/" to="/login"  />
      </Switch>
    </Router>,
    document.getElementById("root")
  );