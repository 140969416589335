import React from "react";
import {Route, Switch} from "react-router-dom";
import axios from "axios";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Button} from 'horizon-components-react';
import { Input } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import SimpleAlertMessage from '../component/alertMessage/SimpleAlertMessage';
import {withRouter} from 'react-router-dom';

axios.defaults.headers.common['x-api-key'] = process.env.REACT_APP_API_KEY;
axios.defaults.headers.common['Content-Type'] = 'application/json';
var passwordValidator = require('password-validator'); // for regex library 

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));



class ChangePasswordInLogin extends React.Component{


  state = {
    newpassword:'',
    newpasswordvalid:false,
    confirmnewpassword:'',
    confirmnewpasswordvalid:false,
    existingpassword:'',
    existingpasswordvalid:false,
    open: true,
    scroll: 'paper',
    allvalid:false,
    showPassword:false,
    showNewPassword:false,
    showConfirmNewPassword:false,
    alert:null,
    isAlert:false,
  }
  // this.handleEmailChange = this.handleEmailChange.bind(this)
  // // this.handlePasswordChange = this.handlePasswordChange.bind(this)
  // this.handleSubmit = this.handleSubmit.bind(this)
  // this.handleChange = this.handleChange.bind(this);
  // this.changePassword =this.changePassword.bind(this)

  handleClickShowPassword = () => this.setState({showPassword:!this.state.showPassword});
  handleClickShowNewPassword = () => this.setState({showNewPassword:!this.state.showNewPassword});
  handleClickShowConfirmNewPassword = () => this.setState({showConfirmNewPassword:!this.state.showConfirmNewPassword});
  handleMouseDownPassword = (event) => event.preventDefault();

  sendAlert=(title,classType,timeout)=>
  {
      var temp={title:title,classType:classType}
      this.setState({alert:temp},()=>{this.setState({isAlert:true})}) //sets alert values, then shows alert
      setTimeout(()=>this.setState(this.setState({isAlert:false})),timeout) //waits "timeout" seconds. then hides alert
  }


handleSubmit=(e)=>{

  e.preventDefault();
  if(this.state.allvalid && (this.state.newpassword=== this.state.confirmnewpassword))
  
  this.changePassword()

  
}


// validateEmail (email) {  // validate email syntax
//   const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//   return re.test(email)
// }


validateNewPassword (newpassword) { // validate newpassword syntax
  var schema = new passwordValidator();
  schema.is().min(8).has().uppercase().has().lowercase().has().digits(); 
console.log(schema.validate(newpassword))
return schema.validate(newpassword)

}
validateConfirmNewPassword (confirmnewpassword) { // validate confirmnewpassword syntax
  var schema = new passwordValidator();
  schema.is().min(8).has().uppercase().has().lowercase().has().digits(); 
console.log(schema.validate(confirmnewpassword))
return schema.validate(confirmnewpassword)

}

validateExistingPassword (existingpassword) { // validate verification code syntax
  var schema = new passwordValidator();
  schema.is().min(8).has().uppercase().has().lowercase().has().digits(); 
console.log(schema.validate(existingpassword))


return schema.validate(existingpassword)

}

handleCancel=(e)=>{ // handle calling of axios function

  e.preventDefault();
this.props.history.push( "/" + this.props.role + "/landing")
}

handleChange = (event)=>{
  // check it out: we get the evt.target.name (which will be either "email" or "password")
  // and use it to target the key on our `state` object with the same name, using bracket syntax
 
  this.setState({confirmnewpasswordvalid :false, allvalid:false, [event.target.name]: event.target.value},() => this.evalchange())
}

evalchange=()=> //evaluate whether individual fields are valid and setting the respectiive states
{
//   const emailvalid = this.validateEmail(this.state.email)
  const newpasswordvalid = this.validateNewPassword(this.state.newpassword) 
  const confirmnewpasswordvalid = this.validateConfirmNewPassword(this.state.confirmnewpassword) 
  const existingpasswordvalid = this.validateExistingPassword(this.state.existingpassword) 


  // const emailvalid = this.validateEmail(this.state.email)
  // const newpasswordvalid = this.validateNewPassword(this.state.newpassword) 
  // const confirmnewpasswordvalid = this.validateConfirmNewPassword(this.state.confirmnewpassword) 
  // const existingpasswordvalid = this.validateExistingPassword(this.state.existingpassword) 
if(this.state.confirmnewpassword ===  this.state.newpassword)
  this.setState({ newpasswordvalid,confirmnewpasswordvalid, existingpasswordvalid},()=>this.checkAllFields())
}

checkAllFields=()=> // check ALL fields are valid before enabling submit button
{

  if( this.state.newpasswordvalid && this.state.confirmnewpasswordvalid && this.state.existingpasswordvalid && this.state.newpassword === this.state.confirmnewpassword) 
  {  this.setState({allvalid:true})
  }
}


 
render(){


  return (
    <div>
      <div>
      <Dialog
        maxWidth={'xs'}
        // fullWidth={'true'}
        open={this.state.open}
        onClose={this.handleClose}
        scroll={this.state.scroll}
        aria-labelledby="scroll-dialog-title"
      >
                 <div >{this.state.isAlert && <SimpleAlertMessage title={this.state.alert.title} classType ={this.state.alert.classType}/> }</div>
        <DialogTitle style={{paddingLeft:"7rem"}}id="scroll-dialog-title"><b>Change Password Form</b></DialogTitle>
        <DialogContent>
          <DialogContentText>
          <form  noValidate autoComplete="off">
      <div>
      <Grid  container  
  alignItems="center"  direction='column'>
      {/* <form >  */}
        
    
      <Grid  xs={6}>
          <label>
          Existing Password: 
          <Input  id="standard-adornment-password"  type={this.state.showPassword ? "text" : "password"}  style={{paddingLeft:"1rem"}} name ="existingpassword"  endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.handleClickShowPassword}
                  onMouseDown={this.handleMouseDownPassword}
                >
                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            } value={this.state.existingpassword} onChange={this.handleChange} error={!this.state.existingpasswordvalid}/>  </label>
          </Grid>

          <Grid item xs={6}>
          <label>
          New Password: 
          <Input id="standard-adornment-password" type={this.state.showNewPassword ? "text" : "password"} style={{paddingLeft:"1rem"}} name="newpassword"  endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.handleClickShowNewPassword}
                  onMouseDown={this.handleMouseDownPassword}
                >
                  {this.state.showNewPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            } value={this.state.newpassword} onChange={this.handleChange} error={!this.state.newpasswordvalid}/>  </label>
          </Grid>

          <Grid item xs={6}>
          <label>
          Confirm New Password: 
          <Input id="standard-adornment-password" type={this.state.showConfirmNewPassword ? "text" : "password"} style={{paddingLeft:"1rem"}} name = "confirmnewpassword"  endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.handleClickShowConfirmNewPassword}
                  onMouseDown={this.handleMouseDownPassword}
                >
                  {this.state.showConfirmNewPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            } value={this.state.confirmnewpassword} onChange={this.handleChange} error={!this.state.confirmnewpasswordvalid}/>  </label>
          </Grid>
          {/* <Button contained color="error"  type ="submit" onClick={(e) => this.handleCancel(e)} style={{marginLeft:"0.5rem"}}>
             Cancel
            </Button>
          <Button contained color="success" disabled={!this.state.allvalid} type ="submit" onClick={(e) => this.handleSubmit(e)} style={{marginLeft:"0.5rem"}}>
             Submit
            </Button> */}
      {/* </form> */}
      </Grid>

    </div>
    </form>

          <br></br>
    <div style={{paddingLeft:'10rem'}}>
      <Button style={{paddingLeft:'10rem'}} contained color="error"  type ="submit" onClick={(e) => this.handleCancel(e)} style={{marginLeft:"0.5rem"}}>
             Cancel
            </Button>
          <Button  style={{paddingLeft:'10rem'}} contained color="success" disabled={!this.state.allvalid} type ="submit" onClick={(e) => this.handleSubmit(e)} style={{marginLeft:"0.5rem"}}>
             Submit
            </Button>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <div>
          <Button contained color="error" onClick={this.handleClear} style={{marginRight:"0.5rem"}}>
           Clear field
          </Button>
          <Button  contained color="success" onClick={this.handleSubmit}>
            Submit
          </Button>
          </div> */}
        </DialogActions>
      </Dialog>
    </div>
    </div>
  );
}

//onsubmit 

changePassword=()=>{   // axios call to change password 

    axios.post(`${process.env.REACT_APP_SSO_API}/changepassword`,{
    
        logintoken:this.props.location.logintoken, 
        existingpassword:this.state.existingpassword,
        newpassword:this.state.newpassword,
        confirmnewpassword:this.state.confirmnewpassword
    
}
).then(res => {
   //console.log(res.data[0])
//   this.props.history.push({pathname:'/auth', newRoleToken:res.data[0].roletoken})
this.sendAlert(res.response.data.message,"ErrorAlert",2000);
console.log(res.response.data.message); 

}).catch(err => {
  this.sendAlert(err.response.data.message,"ErrorAlert",2000);
console.log(err.response.data.message); 
});

}


}

export default withRouter(ChangePasswordInLogin);